import React, { Component } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';

import { Header, Footer } from 'panakeia-react-common/dist';

import { Redirect, Route } from 'react-router';
import { connect } from 'react-redux';
import { Toast } from 'react-bootstrap';
import styled from 'styled-components';

import Button from 'react-bootstrap/Button';
import NavigationBar from './components/NavigationBar';
import { Home } from './components/Page';
import { alerts } from './types/actions/ActionCreators';
import { MainState } from './reducers/MainState';
import { Auth0Context } from './infrastructure/Auth0/Auth0Provider';

import { AUTH_CONFIG } from './constants';
import AuthPage from "./components/Page/AuthPage";

interface IAppDispatch {}

interface IAppProps {
  alertsList: any;
  removeAlert: (alertUuid: string) => void;
}

const ToastWrapper = styled.div`
  position: absolute;
  right: 2px;
  width: 200px;
  z-index: 1000;
`;

class App extends Component<IAppProps & IAppDispatch, any> {
  handleCloseAlert = (alertUuid: string): void => {
    const { removeAlert } = this.props;
    removeAlert(alertUuid);
  };

  async login() {
    const { loginWithRedirect } = this.context;
    await loginWithRedirect();
  }

  async logout() {
    const { logout } = this.context;
    await logout({
      returnTo: AUTH_CONFIG.logout_uri,
    });
  }

  render() {
    const { alertsList } = this.props;
    const { isAuthenticated } = this.context;

    const destinations = {
      home: {
        target: '/',
        displayName: 'Home',
      },
      authorization: {
        target: '/auth',
        displayName: 'Authorization',
      },
    };

    const page = !isAuthenticated ? (
      <div className="page">
        <div className="d-flex flex-column w-100 h-100 justify-content-center align-items-center">
          <h3 className="p-2">You need to be logged in to use PANfactory</h3>
          <Button
            className="btn-lg"
            type="button"
            variant="secondary"
            onClick={() => this.login()}
          >
            Login
          </Button>
        </div>
      </div>
    ) : (
      <div className="page">
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/auth" render={(props) => <AuthPage {...props} />} />
      </div>
    );

    return (
      <div className="App">
        <ToastWrapper>
          {Object.keys(alertsList).map((alertUuid) => (
            <Toast
              animation
              autohide
              delay={3000}
              key={alertUuid}
              onClose={() => this.handleCloseAlert(alertUuid)}
            >
              <Toast.Header>
                <strong className="mr-auto">
                  {alertsList[alertUuid].subject}
                </strong>
              </Toast.Header>
              <Toast.Body>{alertsList[alertUuid].content}</Toast.Body>
            </Toast>
          ))}
        </ToastWrapper>
        <Header
          appName="PANFactory"
          appDescription="Panakeia ML training interface"
        />
        {isAuthenticated && (
          <NavigationBar destinations={destinations} selected="home" />
        )}
        {page}
        {isAuthenticated && (
          <Route
            path="/callback"
            render={() => <Redirect to="/" />}
          />
        )}
        <Footer />
      </div>
    );
  }
}

App.contextType = Auth0Context;

const mapStateToProps = (state: MainState) => ({
  alertsList: state.alerts,
});

const mapDispatchToProps = (dispatch: any) => ({
  removeAlert: (alertUuid: string) => dispatch(alerts.removeAlert(alertUuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
