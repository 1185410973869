export const API_BASE = process.env.REACT_APP_API_BASE
  ? process.env.REACT_APP_API_BASE
  : 'https://panfactory-dev.panakeia.ai:8000';

const baseUrl = window.location.origin;

export const AUTH_CONFIG = {
  domain: 'dev-zg07n4f7.eu.auth0.com',
  client_id: 'OkRnQFDyZGwhbXo43z9F6Ab4bPCHxbW8',
  response_type: 'token id_token',
  audience: 'panfactory',
  redirect_uri: `${baseUrl}/callback`,
  logout_uri: `${baseUrl}`,
  scope: 'openid profile api-access',
};
