import uuidv4 from 'uuid/v4';

import { baseState, MainState } from './MainState';
import { IBaseAction } from '../types/actions/IBaseAction';
import actionTypes from '../types/actions/Action';

export default (state = baseState, action: IBaseAction<any>) => {
  switch (action.type) {
    case actionTypes.ADD_ALERT:
      return state.setIn([MainState.ALERTS(), uuidv4()], {
        subject: action.payload.alertSubject,
        content: action.payload.content,
      });
    case actionTypes.REMOVE_ALERT:
      return state.setIn(
        [MainState.ALERTS()],
        state.getIn([MainState.ALERTS()]).without(action.payload.alertUuid),
      );
    default:
      return state;
  }
};
