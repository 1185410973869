const augParams = {
  fliplr_prob: 0.5,
  flipud_prob: 0.5,
  rot90: [0, 1],
  multiply_hs_scale: 1.1,
  multiply_brightness_scale: 1.1,
};

const maxHyperoptParams = {
  algorithm: 'GRID_SEARCH',
  goal: 'MAXIMIZE',
  hyperparameterMetricTag: 'auc',
  maxTrials: 16,
  maxParallelTrials: 4,
  enableTrialEarlyStopping: false,
  params: [
    {
      parameterName: 'num-tiles',
      type: 'DISCRETE',
      discreteValues: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 30, 40, 50, 100],
    },
  ],
};

const rnnHyperoptParams = {
  algorithm: 'GRID_SEARCH',
  goal: 'MAXIMIZE',
  hyperparameterMetricTag: 'auc',
  maxTrials: 40,
  maxParallelTrials: 4,
  enableTrialEarlyStopping: false,
  params: [
    {
      parameterName: 'num-tiles',
      type: 'DISCRETE',
      discreteValues: [10, 15, 20, 25, 30],
    },
    {
      parameterName: 'rnn-type',
      type: 'CATEGORICAL',
      categoricalValues: ['relu', 'tanh', 'lstm', 'gru'],
    },
    {
      parameterName: 'hidden-size',
      type: 'DISCRETE',
      discreteValues: [128, 256],
    },
  ],
};

const attnHyperoptParams = {
  algorithm: 'GRID_SEARCH',
  goal: 'MAXIMIZE',
  hyperparameterMetricTag: 'auc',
  maxTrials: 20,
  maxParallelTrials: 4,
  enableTrialEarlyStopping: false,
  params: [
    {
      parameterName: 'num-tiles',
      type: 'DISCRETE',
      discreteValues: [10, 15, 20, 25, 30],
    },
    {
      parameterName: 'attn-dims',
      type: 'DISCRETE',
      discreteValues: [128, 256],
    },
    {
      parameterName: 'gated',
      type: 'DISCRETE',
      discreteValues: [0, 1],
    },
  ],
};

const commonParams = {
  image_uri: 'gcr.io/fine-way-271009/pytorch_trainer:master',
  augment_data: false,
  augment_online: false,
  openslide_level: 1,
  percentage_tissue_threshold: 0.75,
  tile_size: 512,
  training_disk_size_gb: 1000,
  repreprocess: false,
  training_seed: 42,
  max_tiles_in_ram: null,
};

const segmentationParams = {
  segmentation_framework: 'pytorch',
  preprocessed_dataset_uuid: 'CHANGE ME',
  prepare_patches: false,
  run_training: true,
  overlap: 64,
  upsample: false,
  colour_normalize_preprocess: 'macenko_wsi_intensity',
  backbone: 'mobilenet_v2',
  batch_size: 10,
  epochs: 3,
  lr: 1e-4,
  loss: 'categorical_crossentropy',
  num_gpus: 1,
  sampler: 'balanced',
  shuffle: false,
  tabulate: 'argmax',
  target_metric: 'mean_iou',
  weights: 'backbone',
};

const profilerParams = {
  task_uuid: '01d0fc91-449d-4042-a4f3-3bd519cd56c1',
  cases_dir: 'tcga-cases',
  colour_normalize_preprocess: null,
  processing_mode: 'tcga',
  use_quadrants: true,
  resize_to_preprocessing: null,
  overlap: 0,
  cancer_segmentation_model_uuid: null,
  only_cancer_tissue: false,
  store_cancer_patches: false,
  percentage_tissue_cancer_threshold: 0.75,
  tensorboard_level: 2,
};

const profilerTrainingParams = {
  prepare_patches: false,
  run_training: true,
  epochs: 20,
  resize_to_online: null,
  test_every: 1,
  online_loading: false,
  validation_mode: false,
  freeze_layers: false,
  hot_layers: null,
  majority_voting: false,
  backbone: 'resnet34',
  profiler_weights: 0.5,
  resample: {
    use_oversampling: true,
    use_undersampling: false,
    ratio: 1.0,
  },
  train_pred_thr: 0.5,
  val_pred_thr: 0.5,
  optimizer: 'Adam',
  weight_decay: 1e-4,
  momentum: 0,
  scheduler_name: '',
  scheduler_params: null,
  ensemble_mode: false,
  ensemble_base_models: null,
  combination_method: 'avg',
  use_balanced_val_dataset: false,
  baseline_model_uuid: '',
};

const getParams = base => {
  switch (base) {
    case 'low-level':
      return {
        task_uuid: '56b99fe0-c6f1-4369-b876-9472d23ff6ab',
        split_uuid: 'b0ffb4fa-aea9-4c36-9577-e5149f4d6d80',
        cases_dir: 'low-level-cases',
        run_basename: 'll-pascal',
        exempt_from_weighting: [],
        ...segmentationParams,
        ...commonParams,
        augmentation_params: augParams,
        hyperopt: {
          algorithm: 'ALGORITHM_UNSPECIFIED',
          goal: 'MAXIMIZE',
          hyperparameterMetricTag: 'mean_iou',
          maxTrials: 1,
          maxParallelTrials: 1,
          enableTrialEarlyStopping: false,
          params: [
            {
              parameterName: 'lr',
              type: 'DOUBLE',
              minValue: 1e-7,
              maxValue: 1e-2,
              scaleType: 'UNIT_LOG_SCALE',
            },
            {
              parameterName: 'multiply-hs-scale',
              type: 'DOUBLE',
              minValue: 1,
              maxValue: 1.5,
              scaleType: 'UNIT_LINEAR_SCALE',
            },
            {
              parameterName: 'multiply-brightness-scale',
              type: 'DOUBLE',
              minValue: 1,
              maxValue: 1.5,
              scaleType: 'UNIT_LINEAR_SCALE',
            },
          ],
        },
      };
    case 'high-level':
      return {
        task_uuid: 'a2ccd357-e594-4575-a847-4b4fc4b74044',
        split_uuid: '8c1c1385-87f5-4a71-bf85-22b2311a1fd2',
        cases_dir: 'high-level-cases',
        run_basename: 'hl-pascal',
        exempt_from_weighting: [6],
        ...segmentationParams,
        ...commonParams,
        augmentation_params: augParams,
        hyperopt: {
          algorithm: 'ALGORITHM_UNSPECIFIED',
          goal: 'MAXIMIZE',
          hyperparameterMetricTag: 'mean_iou',
          maxTrials: 1,
          maxParallelTrials: 1,
          enableTrialEarlyStopping: false,
          params: [
            {
              parameterName: 'lr',
              type: 'DOUBLE',
              minValue: 1e-6,
              maxValue: 1e-2,
              scaleType: 'UNIT_LOG_SCALE',
            },
          ],
        },
      };
    case 'max-mil':
      return {
        split_uuid: 'cfd18887-c942-4b5e-99b2-3938b8890075',
        preprocessed_dataset_uuid: '8e8e8293-6182-4131-8a56-c6a1fd0fa329',
        run_basename: 'er-num-tiles-100',
        batch_size: 256,
        lr: 1e-4,
        num_tiles: 100,
        pooling: 'max',
        ...profilerParams,
        ...profilerTrainingParams,
        ...commonParams,
        augmentation_params: augParams,
        hyperopt: maxHyperoptParams,
      };
    case 'rnn-mil':
      return {
        split_uuid: 'cfd18887-c942-4b5e-99b2-3938b8890075',
        preprocessed_dataset_uuid: '8e8e8293-6182-4131-8a56-c6a1fd0fa329',
        run_basename: 'er-num-tiles-10-relu-256',
        batch_size: 12,
        lr: 1e-5,
        num_tiles: 10,
        pooling: 'rnn',
        rnn_type: 'relu',
        hidden_size: 256,
        ...profilerParams,
        ...profilerTrainingParams,
        ...commonParams,
        augmentation_params: augParams,
        hyperopt: rnnHyperoptParams,
      };
    case 'attn-mil':
      return {
        split_uuid: 'cfd18887-c942-4b5e-99b2-3938b8890075',
        preprocessed_dataset_uuid: '8e8e8293-6182-4131-8a56-c6a1fd0fa329',
        run_basename: 'er-num-tiles-25-128',
        batch_size: 12,
        lr: 1e-5,
        num_tiles: 25,
        pooling: 'attn',
        gated: 0,
        attn_dims: 128,
        ...profilerParams,
        ...profilerTrainingParams,
        ...commonParams,
        augmentation_params: augParams,
        hyperopt: attnHyperoptParams,
      };
    case 'tile-trainer-clustering':
      return {
        split_uuid: 'cfd18887-c942-4b5e-99b2-3938b8890075',
        run_basename: 'biomarker-only-clustering-ae',
        preprocessed_dataset_uuid: 'f036ed62-f2d6-476e-86ae-b48489fba7f5',
        lr: 0.0001,
        upsample: false,
        batch_size: 128,
        num_tiles: 100,
        pooling: 'max',
        tile_sampling_with_replace: true,
        trainer_mode: 'tile',
        target_metric: 'auc_mean',
        ...profilerParams,
        ...profilerTrainingParams,
        ...commonParams,
        epochs: 1,
        tile_size: 224,
        augmentation_params: augParams,
      };
    case 'profiler-preprocessing':
      return {
        split_uuid: 'cfd18887-c942-4b5e-99b2-3938b8890075',
        run_basename: 'profiler-data-generation',
        prepare_patches: true,
        run_training: false,
        bg_params: {
          contrast: 5000,
          r_entropy: 2,
          r_blur: 10,
          fill_holes: true,
        },
        ...profilerParams,
        ...commonParams,
      };

    case 'profiler-test':
      return {
        split_uuid: 'b43c9172-b8ab-412c-bd9a-5e784f76ddc9',
        preprocessed_dataset_uuid: '8acd855a-7519-4da4-b2d4-cfbdde962e97',
        run_basename: 'er-num-tiles-100',
        batch_size: 256,
        lr: 1e-4,
        num_tiles: 100,
        pooling: 'max',
        alpha: 0.5,
        clustering_lambda: 0.025,
        beta: 1.0,
        num_clusters: 5,
        ...profilerParams,
        ...profilerTrainingParams,
        ...commonParams,
        epochs: 1,
        augmentation_params: augParams,
        hyperopt: maxHyperoptParams,
      };
    case 'tiny-exp':
    default:
      return {
        task_uuid: 'a2ccd357-e594-4575-a847-4b4fc4b74044',
        split_uuid: 'f29d1c30-4a03-4721-87fa-b144dfab8b88',
        cases_dir: 'high-level-cases',
        run_basename: 'tiny-exp',
        exempt_from_weighting: [6],
        ...segmentationParams,
        ...commonParams,
        augmentation_params: augParams,
        hyperopt: {
          algorithm: 'ALGORITHM_UNSPECIFIED',
          goal: 'MAXIMIZE',
          hyperparameterMetricTag: 'mean_iou',
          maxTrials: 1,
          maxParallelTrials: 1,
          enableTrialEarlyStopping: false,
          params: [
            {
              parameterName: 'lr',
              type: 'DOUBLE',
              minValue: 1e-6,
              maxValue: 1e-2,
              scaleType: 'UNIT_LOG_SCALE',
            },
          ],
        },
      };
  }
};

export { getParams };
