import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import mainReducer from './reducers/MainReducer';
import { baseState } from './reducers/MainState';

const devToolsConfig = {
  maxAge: 1000,
};

export default function configureStore(initialState = baseState) {
  // @ts-ignore
  const reduxToolsCompose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  const composeEnhancers = reduxToolsCompose
    ? reduxToolsCompose(devToolsConfig)
    : compose;

  return createStore(
    mainReducer,
    initialState,
    composeEnhancers(applyMiddleware(thunk)),
  );
}
