import React from 'react';
import { Link } from 'react-router-dom';
import './NavigationBar.css';

interface INavigatonBarProps {
  destinations: {
    [id: string]: {
      target: string;
      displayName: string;
    };
  };
  selected: string;
}

const NavigationBar = ({ destinations, selected }: INavigatonBarProps) => {
  const links = Object.keys(destinations).map(id => {
    const dest = destinations[id];
    return (
      <Link key={id} to={dest.target}>
        <div className={selected === id ? 'nav-link active' : 'nav-link'}>
          {dest.displayName}
        </div>
      </Link>
    );
  });

  return (
    <div className="d-flex flex-row justify-content-center align-items-center">
      {links}
    </div>
  );
};

export default NavigationBar;
