import React, { Component, SyntheticEvent } from 'react';
import { Button, Form, Row } from 'react-bootstrap';
import ReactJson from 'react-json-view';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { alerts } from '../../types/actions/ActionCreators';
import { factory } from '../../types/actions/AsyncActionCreators';
import { Auth0Context } from '../../infrastructure/Auth0/Auth0Provider';
import { getParams } from './paramsHelper';

interface IHomeState {
  params: any;
  paramsBase: string | undefined;
}

interface IHomeProps {
  addAlert: (subject: string, content: string) => void;
  triggerJob: (authToken: string, payload: any) => void;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 2em;
  border: solid 2px #ccc;
  background-color: #d2dff4;
  min-width: 850px;
`;
const FormContainer = styled(Form)`
  width: 100%;
`;
const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1em 0.8em;
  width: 100%;
`;
const FormSectionHeader = styled.div`
  margin: 0 0 0.8em 0;
  padding: 0.8em 0 0 0.8em;
`;

const paramsBases = [
  'low-level',
  'high-level',
  'max-mil',
  'rnn-mil',
  'attn-mil',
  'tile-trainer-clustering',
  'profiler-preprocessing',
  'profiler-test',
  'tiny-exp',
];

class Homepage extends Component<IHomeProps, IHomeState> {
  constructor(props: any) {
    super(props);
    this.state = {
      params: getParams('low-level'),
      paramsBase: undefined,
    };
  }

  onAdd = (e: any) => {
    this.setState({ params: e.updated_src });
  };

  onEdit = (e: any) => {
    this.setState({ params: e.updated_src });
  };

  onDelete = (e: any) => {
    this.setState({ params: e.updated_src });
  };

  onSubmit = (e: SyntheticEvent<any>) => {
    e.preventDefault();
    const { params } = this.state;
    const { addAlert, triggerJob } = this.props;
    const { callWithToken, isAuthenticated, user } = this.context;
    if (isAuthenticated) {
      callWithToken((authToken: string) =>
        triggerJob(authToken, { ...params, user_id: user.sub }));
    } else {
      addAlert('Error', 'User is not authenticated');
    }
  };

  selectParamsBase = (e: any) => {
    const paramsBase = e.target.value;
    this.setState({ params: getParams(paramsBase), paramsBase });
  };

  render() {
    const { params, paramsBase } = this.state;

    const style = {
      padding: '10px',
      borderRadius: '3px',
      margin: '10px 0px',
    };

    return (
      <Wrapper className="d-flex flex-column align-items-start">
        <FormContainer onSubmit={this.onSubmit}>
          <FormSection>
            <FormSectionHeader>
              <Form.Label column={false}>
                <strong>Submit training job</strong>
              </Form.Label>
            </FormSectionHeader>
            <Form.Group as={Row}>
              From base config:
              <Form.Control
                as="select"
                onChange={this.selectParamsBase}
                size="sm"
                value={paramsBase}
              >
                {paramsBases.map((base) => (
                  <option key={base} value={base}>
                    {base}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group as={Row}>
              <ReactJson
                collapsed={false}
                enableClipboard
                name={false}
                onAdd={this.onAdd}
                onDelete={this.onDelete}
                onEdit={this.onEdit}
                src={params}
                style={style}
                theme="monokai"
              />
            </Form.Group>
            <Button type="submit" variant="success">
              Submit
            </Button>
          </FormSection>
        </FormContainer>
      </Wrapper>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  addAlert: (subject: string, content: string) =>
    dispatch(alerts.addAlert(subject, content)),
  triggerJob: (authToken: string, payload: any) =>
    dispatch(factory.triggerJob(authToken, payload)),
});

Homepage.contextType = Auth0Context;

export default connect(undefined, mapDispatchToProps)(Homepage);
