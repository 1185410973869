import React, { useState, useEffect, useContext } from 'react';
import createAuth0Client from '@auth0/auth0-spa-js';
import { AUTH_CONFIG } from '../../constants';

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext({});
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = ({
  children = undefined,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
}: {
  children: any;
  onRedirectCallback: (appState: any) => void;
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(AUTH_CONFIG);
      setAuth0(auth0FromHook);

      if (window.location.search.includes('code=')) {
        console.log('Got code', window.location)
        const res = await auth0FromHook.handleRedirectCallback();
        const { appState } = res;
        onRedirectCallback(appState);
      }

      const isAuth = await auth0FromHook.isAuthenticated();

      console.log('isAuth', isAuth)

      setIsAuthenticated(isAuth);

      if (isAuth) {
        const usr = await auth0FromHook.getUser();
        setUser(usr);
      }

      setLoading(false);
    };
    initAuth0();
    // eslint-disable-next-line
    }, []);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    const usr = await auth0Client.getUser();
    setUser(usr);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const usr = await auth0Client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(usr);
  };

  const callWithToken = (func: (idToken: string) => void) => {
    if (isAuthenticated) {
      auth0Client.getTokenSilently().then((idToken: string) => func(idToken));
    }
  };

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        callWithToken,
        getIdTokenClaims: (...p: any[]) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p: any[]) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p: any[]) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p: any[]) => auth0Client.getTokenWithPopup(...p),
        logout: (...p: any[]) => auth0Client.logout(...p),
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
