// @ts-ignore
import SeamlessImmutableFunc from 'seamless-immutable';

export class MainState {
  public static ALERTS = () => 'alerts';

  public alerts: {
    [alertUuid: string]: { subject: string; content: string };
  } = {};
}

export const baseState = SeamlessImmutableFunc<MainState>(new MainState());
