import actionTypes from '../Action';

export function addAlert(subject: string, content: string) {
  return {
    type: actionTypes.ADD_ALERT,
    payload: {
      content,
      alertSubject: subject,
    },
  };
}

export function removeAlert(alertUuid: string) {
  return {
    type: actionTypes.REMOVE_ALERT,
    payload: {
      alertUuid,
    },
  };
}
