import { Action } from 'redux';

import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';
import { MainState } from '../../../reducers/MainState';
import { API_BASE } from '../../../constants';
import { alerts } from '../ActionCreators';

export const triggerJob = (authToken: string, jobParams: any) => (
  dispatch: ThunkDispatch<MainState, any, Action>,
) => {
  const url = `${API_BASE}`;
  axios
    .put(url, jobParams, { headers: { authorization: `Bearer ${authToken}` } })
    .then(() => {
      dispatch(alerts.addAlert('Success', 'Job launched'));
    })
    .catch(() => {
      dispatch(alerts.addAlert('API Error', 'Error creating job'));
    });
};
